import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import HorizontalArticle from "../../components/horizontal-article/HorizontalArticle";

// images
import articlePlaceholder from "../../assets/images/comment-letters-placeholder.jpg";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import { getPostBySlug } from "../../Store/Action/PostAction.js";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import withURLSync from "./URLSync";
import "./search.scss";
import placeholderImage from "../../assets/images/comment-letters-placeholder.jpg";

import {
  InstantSearch,
  Hits,
  HierarchicalMenu,
  RefinementList,
  SearchBox,
  SortBy,
  Stats,
  Pagination,
  Panel,
  ClearRefinements,
  RatingMenu,
  RangeInput,
  Highlight,
  Configure,
  connectStateResults,
  Snippet,
  connectSearchBox,
} from "react-instantsearch-dom";
import { getResourceLinks } from "../../dev-config/global.js";
import { Box } from "@mui/material";

const searchClient = algoliasearch("HGGCT1WR6X", "6ba4dbf1d041e3c8976252f142ffd6bd");
const CustomSearchBox = ({ currentRefinement, refine, queryFilter }) => {
  useEffect(() => {
    refine(queryFilter);
  }, [refine, queryFilter]);

  return (
    <input
      type="search"
      className="hidden"
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
      placeholder="Search"
    />
  );
};
const ConnectedCustomSearchBox = connectSearchBox(CustomSearchBox);

const Post = (props) => {
  const { category, slug, queryFilter } = useParams();
  const [slugVal, setSlugVal] = useState("");

  useEffect(() => {
    setSlugVal(slug);
  }, [slugVal]);

  const slugToHeaderConversion = (slug) => {
    var value = slug?.replace(/-/g, " ");
    switch (slug) {
      case "em-coding-billing":
        value = "E&M, Coding And Billing";
        break;
      case "education-publications":
        value = "Education & Publications";
        break;
      case "pbm-horror-stories":
        value = "PBM Horror Stories";
        break;
      case "coa-newsletters":
        value = "COA Newsletters";
        break;

      case "news-updates":
        value = "News & Updates";
        break;

      case "pbm-tools":
        value = "PBM Tools";
        break;

      default:
        return value;
    }
    return value;
  };

  return (
    <>
      {/* <PrimaryHeader /> */}
      <PageWrapper className="article-category pt-6 pb-0">
        <Container>
          <Card className="inner-wrapper border-0 pt-5">
            <h1 className="text-center text-capitalize  mb-3">{slugToHeaderConversion(slug)}</h1>

            <div className="search-inner">
              <InstantSearch
                searchClient={searchClient}
                indexName="prod_myCOA"
                searchState={props.searchState}
                createURL={props.createURL}
                onSearchStateChange={props.onSearchStateChange}
              >
                <ConnectedCustomSearchBox queryFilter={queryFilter} />
                <Configure hitsPerPage={16} />
                <Row>
                  {/* <Col lg="3" className="d-none">
                    <Facets slugSearch={slugVal} />
                  </Col> */}
                  {/* {(slug === "education-publications" || slug === "news-updates") && ( */}
                  <Col
                    lg="3"
                    className={
                      slug === "education-publications" || slug === "news-updates" ? " " : "d-none"
                    }
                  >
                    <Facets slugSearch={slugVal} />
                  </Col>
                  {/* )} */}

                  <Col
                    lg={slug === "education-publications" || slug === "news-updates" ? "9" : "12"}
                  >
                    <SearchBoxWrapper
                      className={
                        slug === "education-publications" || slug === "news-updates"
                          ? "full-width"
                          : "contained"
                      }
                    >
                      <SearchBox
                        translations={{ placeholder: "Search for article" }}
                        // value={query}
                        className="search-box"
                      />

                      <div
                        className="d-block d-sm-flex align-items-center justify-content-between flex-wrap"
                        style={{ gap: "15px" }}
                      >
                        <ResultTopBar className="results-topbar">
                          <div className="text-warning flex-grow  py-30 d-flex align-items-center">
                            <Stats />
                          </div>
                        </ResultTopBar>

                        <SortByWrapper className="sort-by d-block d-sm-flex justify-content-end  mb-sm-0">
                          <label className="sr-only">Sort by</label>
                          <SortBy
                            items={[
                              { value: "prod_myCOA", label: "Latest" },
                              { value: "prod_myCOA_sortBy_dateASC", label: "Oldest" },
                            ]}
                            defaultRefinement="prod_myCOA"
                          />
                        </SortByWrapper>
                      </div>
                    </SearchBoxWrapper>
                    <CustomResults />
                  </Col>
                </Row>
              </InstantSearch>
            </div>
          </Card>
        </Container>
      </PageWrapper>
      {/* <PrimaryFotoer /> */}
    </>
  );
};

const transformItems = (items) => {
  return items.map((item) => {
    let label = item.label.replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
    if (item.label === "em-coding-billing") {
      label = "E&M Coding Billing";
    } else if (item.label === "education-publications") {
      label = "Education & Publications";
    }
    return { ...item, label };
  });
};

const Facets = ({ slugSearch }) => (
  <aside className="p-0" style={{ position: "sticky", top: "130px" }}>
    <h4 style={{ fontWeight: "600", fontSize: "18px" }} className="mb-3">
      FILTERS
    </h4>
    <hr style={{ marginTop: "21px", marginBottom: "21px" }} />
    <CategoryWrapper>
      <Panel header="Categories" className="mt-1">
        <RefinementList
          attribute="categories_slugs.slug"
          defaultRefinement={[slugSearch]}
          operator="and"
          showMore={false}
          limit={100}
          escapeFacetValues={false}
          transformItems={transformItems}
        />
      </Panel>
    </CategoryWrapper>

    <CategoryWrapper>
      <Panel header="Initiatives">
        <RefinementList
          attribute="initiative"
          defaultRefinement="CAN"
          operator="and"
          showMore={false}
          limit={7}
        />
      </Panel>
    </CategoryWrapper>
    <hr style={{ marginTop: "21px", marginBottom: "21px" }} />
    <ClearSearchButton>
      <ClearRefinements
        translations={{
          reset: "Clear all",
        }}
      />
    </ClearSearchButton>
  </aside>
);

const Hit = ({ hit }) => {
  const icons = [];
  for (let i = 0; i < 5; i++) {
    const suffixClassName = i >= hit.rating ? "--empty" : "";
    const suffixXlink = i >= hit.rating ? "Empty" : "";

    icons.push(
      <svg
        key={i}
        className={`ais-RatingMenu-starIcon ais-RatingMenu-starIcon${suffixClassName}`}
        aria-hidden="true"
        width="24"
        height="24"
      >
        <use xlinkHref={`#ais-RatingMenu-star${suffixXlink}Symbol`} />
      </svg>
    );
  }
  const [links, setLinks] = useState([]);
  useEffect(() => {
    getResourceLinks(hit?.categories_slugs).then((data) => {
      setLinks(data);
    });
  }, [hit?.categories_slugs]);

  let category = hit?.categories_slugs?.[0]?.slug;
  let subCategory = hit?.categories_slugs?.[1]?.slug;
  let slug = hit?.slug;
  //let baseUrl = process.env[`REACT_APP_${hit?.initiative?.[0] ? hit.initiative[0] : "CAN"}`];
  let baseUrl = process.env.REACT_APP_BASE_CAN;
  let redirectUrl = baseUrl + "/" + category + "/" + subCategory + "/" + slug;
  const sanitizedSnippet = hit?._snippetResult?.body
    ? hit._snippetResult.body.value
        .replace(/&ndash;/g, "–")
        .replace(/&amp;/g, " ")
        .replace(/&nbsp;/g, " ")
        .replace(/&rsquo;/g, "’")
        .replace(/&ldquo;/g, "“")
        .replace(/&rdquo;/g, "”")
        .replace(/&gt;&gt;/g, " ")
    : "";
  const sanitizedSnippetHead = hit?._snippetResult?.title
    ? hit._snippetResult.title.value.replace(/&amp;/g, " ")
    : "";

  let date = hit.date
    ? new Date(hit.date).toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : "";
  return (
    <article className="hit">
      <div
        className="hit-image cursor-pointer"
        onClick={() => {
          window.location.replace(redirectUrl);
        }}
      >
        <img src={hit?.feature_image ? hit?.feature_image : placeholderImage} />
      </div>
      <div className="product-desc-wrapper">
        <div className="hit-content">
          <div
            className="product-title cursor-pointer"
            onClick={() => {
              window.location.replace(redirectUrl);
            }}
          >
            <Highlight
              attribute="title"
              hit={{ ...hit, _snippetResult: { body: { title: sanitizedSnippetHead } } }}
            />
          </div>
          <div className="hit-description">
            <div>
              {/* {sanitizedSnippet} */}
              {hit?.slug == '2025-community-oncology-conference' ?
                <Box sx={{ ' p': { mt: 0, display: 'inline', 'a': { fontWeight: '600', color: 'primary.main', textDecoration: 'underline' } } }} dangerouslySetInnerHTML={{
                  __html: hit?._highlightResult?.body?.value
                    .replace(/\s+/g, ' ')
                    .replace(/(\r\n|\n|\r)/g, '')
                }} />
                :
                <Snippet attribute='body' hit={{ ...hit, _snippetResult: { body: { value: sanitizedSnippet } } }} />
              }
            </div>

            <div class=" meta d-flex align-items-center flex-wrap " style={{ gap: "0.5rem" }}>
              <Badge pill className="mr-50 mt-50 mr-1" color="background">
                <Highlight attribute="initiative" hit={hit} />
              </Badge>
              <div className="divider" />
              { links?.map((parent, index) => {
                  return (
                    <>
                      <span
                        className="cursor-pointer weight-500"
                        onClick={() => {
                          window.open(baseUrl + parent?.link, '_blank');
                        }}
                        style={{ color: "var(--link)", fontWeight: 500 }}
                      > <b>{parent?.name} </b> </span>{" "}
                      <div className="divider" /> 
                      {parent?.children?.map((child, index) => {
                        return (
                          <>
                            <span
                              className="cursor-pointer weight-500"
                              onClick={() => {
                                window.open(baseUrl + child?.link, '_blank');
                              }}
                              style={{ color: "var(--link)", fontWeight: 500 }}
                            > {child?.name} </span>{" "}
                            <div className="divider" />
                          </>
                        );
                      })
                      }
                    </>
                  );
                })
                }
              <span className="date">{date}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

const CustomResults = connectStateResults(({ searchState, searchResults }) => (
  <div className="results-wrapper p-0">
    <hr className="mb-5 mt-5 border-primary" />

    {searchResults && searchResults.nbHits ? (
      <SearchResults style={{ paddingBottom: "50px" }}>
        <Hits hitComponent={Hit} />
        <footer>
          <Pagination showLast={true} />
        </footer>
      </SearchResults>
    ) : (
      <div className="no-results pb-5 ">
        No results found matching &quot;
        <span className="query">{searchState.query}</span>
        &quot;
      </div>
    )}
  </div>
));

export default withURLSync(Post);

export const PageWrapper = styled.div`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);
  position: relative;
  top: -2px;

  .inner-wrapper {
    padding: 30px;
    padding-bottom: 0;
    border-radius: 30px;
  }

  .hidden {
    display: none;
  }
`;

export const SearchBoxWrapper = styled.div`
  &.contained {
    max-width: 698px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    display: flex;
    margin-bottom: 10px;

    &:focus-within {
      outline: 3px solid #c5ccdc;
      outline-offset: 0px;
      border-radius: 0.4rem;
    }

    input {
      width: 100%;
      box-shadow: unset;
      outline: unset;
      padding-left: 15px;
      height: 3.5rem;
      border: 2px solid var(--primary);
      flex: 1;
      border-radius: 0.4rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 16px;

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .ais-SearchBox-reset {
      background-color: var(--danger);
      border-color: var(--danger);
    }

    .ais-SearchBox-input:not([value=""]) + button {
      display: none !important;
    }

    button {
      background-color: var(--primary);
      box-shadow: unset;
      padding: 10px 15px;
      border: 2px solid var(--primary);
      outline: none;
      border-radius: 0.4rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      svg {
        stroke: #fff;
        fill: #fff;
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export const SortByWrapper = styled.div`
  select {
    padding: 6px;
    border-color: silver;
    border-radius: 2px;
    min-width: 150px;
    width: 100%;
  }
`;

export const ResultTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;

  .alert {
    flex: 1;
    margin-bottom: 0;
  }

  @media (max-width: 453px) {
    flex-direction: column;

    .alert {
      padding: 0.5rem;
    }
  }
`;

export const SearchResults = styled.div`
  .ais-Hits-list {
    li:not(:last-child) {
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 40px;
      margin-bottom: 33px;
    }
  }
  article {
    display: flex;
    gap: 24px;

    &:hover {
      .hit-image {
        img {
          transform: scale(1.05);
        }
      }
    }

    .hit-image {
      flex: 0 0 250px;

      img {
        width: 100%;
        transition: all 0.3s ease 0s;
        aspect-ratio: 16 / 10;
        object-fit: cover;
        border-radius: 10px !important;
        cursor: pointer;
      }
    }

    .product-desc-wrapper {
      .product-title {
        margin-bottom: 5px;
        cursor: pointer;
        /* span {
          color: var(--primary);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 18px;
          line-height: 1.4;
        } */

        /* .ais-Highlight {
          *:not(:first-child) {
            display: none;
          }
        } */

        .ais-Highlight {
          * {
            color: var(--primary);
            display: inline;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            font-style: normal;
          }

          .ais-Highlight-highlighted {
            background-color: yellow;
          }
        }
      }

      .hit-description {
        /* .ais-Snippet-nonHighlighted {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden; */

        /* &:not(:first-child) {
            display: none;
          } */
        /* } */

        /* .ais-Snippet-highlighted {
          display: none;
        } */

        .ais-Snippet {
          * {
            display: inline !important;
            font-style: normal;
            margin-bottom: 0.8rem;
          }

          .ais-Snippet-highlighted {
            background-color: yellow;
          }
        }

        .meta {
          margin-top: 0.8rem;
          font-size: 14px;
          .cursor-pointer {
            cursor: pointer;
          }

          .ais-Highlight {
            color: var(--gray);
            text-transform: uppercase;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #8592a3;
          }

          .date {
            color: rgba(68, 68, 68, 0.6);
            text-transform: capitalize;
            font-weight: 500;
          }

          .divider {
            background: #bdbdbd;
            width: 8px;
            height: 8px;
            border-radius: 50px;
          }
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;

      .hit-image {
        flex-basis: 100%;
      }
    }
  }

  .ais-Pagination {
    ul {
      margin-bottom: 0;
    }
    li:last-child {
      margin-bottom: 0.6rem;
    }
    a.ais-Pagination-link--selected {
      background-color: var(--primary);
      color: #fff;
    }
  }
`;

export const ClearSearchButton = styled.div`
  button {
    background-color: var(--danger);
    color: #fff;
    border: 1px solid var(--danger);
    box-shadow: unset;
    display: block;
    width: 100%;
    padding: 12px 10px;
    border-radius: 0.245rem;
    font-size: 16px;
    cursor: pointer;

    &.ais-ClearRefinements-button--disabled {
      background-color: #b9b9b9;
      border-color: #b9b9b9;
    }
  }
`;

export const CategoryWrapper = styled.div`
  /* .inner-wrapper {
    padding: 30px;
    padding-bottom: 0;
    border-radius: 30px;
  } */

  .ais-Panel-header {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 18px;
  }
  .hidden {
    display: none !important;
  }

  label {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
  }

  .ais-RefinementList-count {
    margin-left: auto;
  }
  .ais-RefinementList-checkbox {
    margin-right: 5px;
  }

  .ais-RefinementList-item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .ais-RefinementList-labelText {
    position: relative;
    top: -4px;
  }
  .ais-RefinementList-showMore {
    background-color: #e3f3ff;
    color: #173272;
    border: 1px solid #d3e9fc;
    box-shadow: unset;
    padding: 10px 12px;
    border-radius: 0.245rem;
    font-size: 14px;
    cursor: pointer;
    line-height: 1;
  }
`;
