import axios from 'axios'
export const createProductTemplate = (data) =>{
    return async dispatch => {
        await axios.post(`/product/postData`,data)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_PRODUCTS',
                    data: response.data.data
                })
            });
    }
}

export const updateProductTemplate = (data) =>{
    
    return async dispatch => {
        await axios.post(`/product/updateData`,data)
            .then(response =>  {
                dispatch({
                    type: 'EDIT_PRODUCT',
                    data: response.data.data
                })
            });
    }
}

export const listProductTemplate = () =>{
    return async dispatch => {
        await axios.get(`/product/list`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_PRODUCTS',
                    data: response.data.data
                })
            });
    }
}

export const listMyProductTemplate = (userID) =>{
    return async dispatch => {
        await axios.get(`/product/mylist/${userID}`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_PRODUCTS',
                    data: response.data.data
                })
            });
    }
}


export const listProductCategory = () =>{
    return async dispatch => {
        await axios.get(`product/category`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_CATEGORY',
                    data: response.data.data
                })
            });
    }
}

export const createCategoryTemplate = (data) =>{
    return async dispatch => {
       // var parameters = data.split('/');
        await axios.post(`/product/categoryData`,data)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_CATEGORY',
                    data: response.data
                })
            });
    }
}
// ** Get Email Data
export const showProductDetails = (id) => {
  return async dispatch => {
    await axios.get(`/product/getProductdetails/${id}`)
      .then(response =>  {
                dispatch({
                    type: 'GET_PRODUCT_DETAILS',
                    data: (response.data && response.data.data ? response.data.data : [])
                })
            });
  }
}

export const createComment = (data) =>{
    return async dispatch => {
        await axios.post(`/product/postcomment`,data)
            .then(response =>  {
                // console.log('red',response.data);
                dispatch({
                    type: 'GET_PRODUCT_DETAILS',
                    data: (response.data ? response.data : [])
                })
                dispatch({
                    type: 'UPDATE_PRODUCTS',
                    data: response.data.data
                })
            });
    }
}
   
/*export const showProductDetails = (data) =>{
    return async dispatch => {
        await axios.get(`/product/getdetails/`+data)
            .then(response =>  {
                dispatch({
                    type: 'GET_PRODUCT_DETAILS',
                    data: response.data
                })
            });
        
    }
}*/