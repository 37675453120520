import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "./_singlePostStyle";
import { Col, Row, Container } from "reactstrap";
import SocialShare from "../../../components/social-share/SocialShare";
import { FileText } from "react-feather";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import parse from "html-react-parser";
import { useParams, Link, useHistory } from "react-router-dom";

// Templates
import CommonTemplate from './template/CommonTemplate'
import RecommendedVideos from './template/RecommendedVideos'
import RecommendedResources from './template/RecommendedResources'
import FullWidthTemplate from './template/FullWidthTemplate'
import AccessDenied from "../../access-denied/AccessDenied.js";
import GridTemplate from "./template/GridTemplate.js";
import EventTemplate from "./template/EventTemplate.js";

const PageView = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const [userMetadata, setUserMetadata] = useState(null);
  const [accessDenied, setAccessDenied] = useState(false);

  const initiativeAccess = [ process.env.REACT_APP_INITIATIVE ];

  const domain = "login.mycoa.io";

  const { category, subcategory ,slug } = useParams();

  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [allPost, setAllPost] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setPost(null)
    setRelatedPosts([])
    axios.post(`${process.env.REACT_APP_API_URL}/resource/single-page`,{slug:slug,limit:8, initiative: initiativeAccess }).then((response) => {
      // if (response?.data?.data?.post && response?.data?.data?.post?.short_description == null) {
      //   response.data.data.post.short_description = response?.data?.data?.post?.body?.substring(0, 500) + ' ...'
      // } else if(response.data.data?.post) { 
      //   response.data.data.post.short_description = response?.data?.data?.post?.short_description + ' ...' 
      // }
      setPost(response.data.data.post);
      if(response.data.data.post === null 
        || ((response?.data?.data?.post?.allowed_categories_slugs.includes(category) == false)
        || (response?.data?.data?.post?.allowed_categories_slugs.includes(subcategory) == false))){
          setAccessDenied(true);
        }
        else if(response.data.data.post != null && response.data.data.post.publish_type === "Save Draft"){
          setAccessDenied(true);
        }
      setRelatedPosts(response.data.data.related_posts);
      setAllPost(response.data.data.posts)
      console.log(relatedPosts,'related_posts')
    });
  }, [slug]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
      console.log('result', result)
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COA") !== -1 && result != undefined) {
        document.querySelector("#authCan")?.classList.remove("hide");
        document.querySelector("#noAuthCan")?.classList.add("hide");
      } else {
        document.querySelector("#authCan")?.classList.add("hide");
        document.querySelector("#noAuthCan")?.classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);

  const rendorTemplate = (templateName) => {
      var template =  <CommonTemplate  
                          post={post}
                          relatedPosts={relatedPosts}
                          isAuthenticated={isAuthenticated}
                          publicView={post && (post.public_view==undefined || post.public_view==false)? false:true}
                        />
    switch (templateName) {
      case 'rightSidebar':
        template = template
        break;
      case 'leftSidebar':
        template = template
        break;
        case 'fullWidth':
          template = <FullWidthTemplate
                        post={post}
                        relatedPosts={relatedPosts}
                        allPost={allPost}
                        isAuthenticated={isAuthenticated}
                        publicView={post && (post.public_view==undefined || post.public_view==false)? false:true}
                      />
          break;
      case 'Recommended Videos':
        template = <RecommendedVideos
                      post={post}
                      isAuthenticated={isAuthenticated}
                      relatedPosts={relatedPosts}
                      publicView={post && (post.public_view==undefined || post.public_view==false)? false:true}
                    />
        break;
      case 'Recommended Resources':
        template = <RecommendedResources
                      post={post}
                      isAuthenticated={isAuthenticated}
                      relatedPosts={relatedPosts}
                      publicView={post && (post.public_view==undefined || post.public_view==false)? false:true}
                    />
        break;
  case 'Grid View':
        template = <GridTemplate
                      post={post}
                      relatedPosts={relatedPosts}
                      allPost={allPost}
                      isAuthenticated={isAuthenticated}
                      publicView={post && (post.public_view==undefined || post.public_view==false)? false:true}
                    />
        break;
    }

    if (post?.type === "Event") { 
      template = (
        <EventTemplate
        post={post}
        relatedPosts={relatedPosts}
        allPost={allPost}
        isAuthenticated={isAuthenticated}
        publicView={
          post && (post.public_view == undefined || post.public_view == false) ? false : true
        }
        />
      );
    }
    
      return template
  }

  return (!accessDenied) ? (
    <>
      {rendorTemplate(post?.name)}
      { post && (post?.public_view===false ||  post?.public_view==undefined)? <PostTeaser />: ''}
    </>) : 
    (<AccessDenied />);
};

export default PageView;
